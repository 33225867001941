import gogApi from './gogApi';
import defAlbums from '../consts/defaultAlbums';

const defaultAlbumsService = (httpClient: any, isViewer: boolean = false) => {
  const { getGogAlbums, getMasterGogData } = gogApi(httpClient);

  const getDefaultAlbums = async (
    defaultAlbumsIds: string[],
    instance: string,
  ) => {
    if (defaultAlbumsIds && defaultAlbumsIds.length > 0) {
      return getTemplateDefaultAlbums(defaultAlbumsIds, instance);
    }
    return getNewDefaultAlbums();
    // const d = defAlbums;
    // return [
    //   d.babyLori,
    //   // d.rachelSam,
    //   d.familyEverAfter,
    //   d.joshuaAshley,
    //   d.lynnRichard,
    //   d.babyAiden,
    // ];
  };

  const getNewDefaultAlbums = async () => {
    const ids = [
      '590154da-37f0-415a-bfad-9340892925e5',
      'c50ffb1c-66a8-4c88-b66b-4c2f39c11f38',
      '3bd21aa6-8832-4ce8-a81b-4598698c965b',
    ];
    const masterGogData = await getMasterGogData();
    const albums = masterGogData.albums.filter((x: any) =>
      ids.includes(x.albumId),
    );
    return albums;
  };

  const getTemplateDefaultAlbums = async (
    defaultAlbumsIds: string[],
    instance: string,
  ) => {
    let defaultAlbums: GogAlbum[] = [];

    const masterGoG = await getGogAlbums(
      '1a277b49-ad04-4139-a4ab-8b5ac24205a5',
      instance,
      'TPASection_ihuk8wkg',
      isViewer,
    );

    if (masterGoG) {
      let id: string, i, gmi;
      const gogMasterHash: { [id: string]: any } = {};

      // map gog master array to hash
      for (gmi = 0; gmi < masterGoG.albums.length; gmi++) {
        const a = masterGoG.albums[gmi];
        gogMasterHash[a.albumId] = a;
      }
      // build array
      for (i = 0; i < defaultAlbumsIds.length; i++) {
        id = defaultAlbumsIds[i];
        if (defAlbums[id]) {
          defaultAlbums.push(defAlbums[id]);
        } else {
          if (gogMasterHash[id]) {
            defaultAlbums.push(gogMasterHash[id]);
          }
        }
      }
    } else {
      defaultAlbums = await getNewDefaultAlbums();
    }
    return defaultAlbums;
  };

  return { getDefaultAlbums, getTemplateDefaultAlbums };
};

export default defaultAlbumsService;
